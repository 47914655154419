 .chip.risk {
  color: #49300b;
  background: #F5A027;
  border: solid 1px #ab701b;
 }

.chip.protective {
  color: #2f3f3d;
  background: #9DD5CD;
  border: solid 1px #6d958f;
 }

.chip.risk[data-state="on"]{
  animation: pulse 1.5s infinite;
  border: solid 1px rgba(243, 156, 18, 1.0) !important;
}

 @keyframes pulse {
   0% {
     background: rgba(255, 234, 167, 1.0);
     box-shadow: 0 0 0 0 rgba(241, 196, 15, 0.7);
   }
   
   70% {
     box-shadow: 0 0 0 10px rgba(243, 156, 18, 0);
   }
 }